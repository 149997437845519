*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    text-decoration: none;
    font-family: "Mrgvlovani-Regular";
    list-style: none;

    
  }
  
  
  body{
    background-color: #F6F8F9;
  }
  svg{
    color: white;
    font-size: 19px;
  }
  .margin-280px{
    margin: 0 15vw;
  }
  .padding-280px{
    padding: 0 15vw;
    
  }
  /* HEADER START */
  .header-wrapper{
    height: 195px;
  }
  nav.padding-280px{
    position: fixed;
    width: 100vw;
    height: 195px;
    z-index: 120;
    z-index: 530;
  }
  .transparent{
    background-color: transparent;
    transition:all 0.5s ease-out;
    border-bottom: 1px solid #4A6578;
    
  }
  @media only screen and (max-width: 500px){

  }
  .colorful{
    transition: all 0.5s ease;
    background-color: #04243F;
  }
  .wrapperr{
    display: none;
  }
  nav{
    
    
    height: 195px;
  }
  .topNav{
    
    
    display: flex;
    justify-content: space-between;
  }
  .topNav img{
    margin-top: 10px;
    height: 95px;
  }
  
  .topNav-last{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .topNav-last svg{
    margin: 0 10px;
    cursor: pointer;
  }
  .topNav-last input{
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    transition: all 1s ease;
    border:none;
    border-bottom: 1px solid;
  }
  .topNav-last .hover input{
    width: 170px;
    max-width: 15vw;
  }
  .hover svg{
    transition: all 2s ease-out;
  }


  .topNav-last .hover:hover input{
    border-color: white;
    color: white;

  }
  
 
  
  ::placeholder {
    margin-left: 15px;
    padding-left: 15px;
    
  }
  
  .topNav-socials{
    display: flex;
    justify-content: space-between;
    padding-right:10vw;
    width: 300px;
    align-items: center;
    height: 100%;
    max-width: 30vw;
  }
  .topNav-socials .youtube svg{
    font-size: 22px;
  }
  .bottomNav{
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  
  .hotline{
    padding: 15px;
    border: 1px solid white;
    border-radius: 30px;
    
  }
  .hotline span{
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-feature-settings: 'case' on;
    font-family: Firago-Regular;
    
  }
  .mainColor{
    color: #00A859 !important;
  }
  .hotline span > span{
    color: transparent;
  }
  .bottomNav-contact{
    padding: 10px 20px;
    border-radius: 20px;
    color: white;
    background-color: #00A859;
    cursor: pointer;
    transition: all .5s ease;
  }
  .bottomNav-contact:hover{
    background-color:#05243F;
    
    transition: all .5s ease-in;
  }
  .bottomNav-contact:hover span a,.bottomNav-contact span:hover a,.bottomNav-contact span a:hover{
    color: #00A859;
    transition: all .5s ease-in;
  }
  .bottomNav-contact:hover span a:visited,.bottomNav-contact span:hover a:visited,.bottomNav-contact span a:visited:hover{
    color: #00A859;
    transition: all .5s ease-in;
  }
  .bottomNav-contact span{
    font-size: 16px;
  }
  .bottomNav-contact span a{
    color: white;
    font-feature-settings: 'case' on;
    text-transform: uppercase;
    font-family: Firago-Medium;
  }
  .bottomNav-contact span a:visited{
    color: white;
  }

  /* Hover menu buttons  start*/ 

  .dropbtn {
    background-color: transparent;
    color: white;
    
    font-size: 17px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
   
    width: fit-content;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-content {
    
    display: none;
    position: absolute;
    background-color: white;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transition: all 1s ease;
    
  }
  div.biggie{
    display: none;
    position: absolute;
    background-color: white;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: all 1s ease;
    z-index: 1;

  }
  
  .dropdown-content a {
    width: 400px;
    font-family: FiraGo-Light;
    color: black;
    padding: 10px 16px;
    font-size: 14px;
    text-decoration: none;
    display: block;
    font-feature-settings: 'case' on;
    
    
  }
  
  .dropdown-content a:hover {
    transform: all 1s ease;
    
    background-color:#00A859;
    color: white;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    transition: all 1s ease;
    
    
  }
 

  .dropdown:hover button{
    color: #00A859;
  }
  .dropdown:hover button svg{
    color: #00A859;
  }
  
  .dropdown button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-feature-settings: 'case' on;
  }
  .dropdown button svg{
    margin-left: 3px;
    font-size: 15px;
  }
  .dropdown:hover .dropbtn {
    background-color: transparent;
  }



   .aqtebi{
    position: relative;
  }
   .aqtebi:hover .aqtebi-a{
    display: block !important;
    position: absolute;
    
    width: 300px;
   
    font-family: FiraGo-Regular;
    color: black;
   
    font-size: 14px;
    text-decoration: none;
    
    transform: all 1s ease;
    right: -300px;
    bottom:0;
    background-color: white;
  }

   .aqtebi-a{
    display: none;
    position: absolute;
    
    width: 300px;
   
    font-family: FiraGo-Regular;
    color: black;
   
    font-size: 14px;
    text-decoration: none;
    
    transform: all 1s ease;
    right: -300px;
    bottom:0;
    background-color: white;
    
  }
  


  /* Hover menu buttons end*/
  
  /* DROPDOWN LANGUAGE START */

  .search .dropdown {
    
    padding-left: 10px;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    border-left: 1px solid white;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search .dropdown svg{
    color: #00A859;
    margin: 0 5px;
  }
  
  .dropdown-header {
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Mrgvlovani-caps;
  }
  
  .dropdown-body {
    padding: 5px;
    color: white;
    display: none;
    position: absolute;
    top: 80%;
    background-color: transparent;
    width: 100%;
  }
  
  .dropdown-body.open {
    display: block;
  }
  
  
  
  .dropdown-item:hover {
    cursor: pointer;
  }
  
  .dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
  }
  
  .dropdown-item-dot.selected {
    opacity: 1;
  }
  
  .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
  }
  
  .icon.open {
    transform: rotate(90deg);
  }

  /* DROPDOWN LANGUAGE END */

  /* HEADER END */

  /* FOOTER START */
  .footer{
    padding-top: 50px;
    background: white;
    position: relative;
  }
 .topFooter{
  display: flex;
  justify-content: space-between;
  padding-bottom: 140px;
  flex-wrap: wrap;
  
 }
 .topFooter > *{
  cursor: pointer;
  
 }
 .topFooter div{
  flex: 0 0 15vw;
  position: relative;
  transition: all 0.5s ease;
  bottom: 0;
 }
 .topFooter div:hover{
  
  
  bottom: 50px;
 }
 .topFooter div img{
  width: 100%;
 }
 .footer-gmail{
  top: -11%;
  left: 0%;
  position: absolute;
  background-color: #00A859;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 180px;
  width: 70vw;
  flex-wrap: wrap;
  overflow: hidden;
  
 }
 .footer-gmail > *{
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 20px;
 }
 .footer-gmail:first-child{
  padding-left: 50px;
 }
 .footer-gmail div:last-child{
  padding-right: 50px;
 }
 .footer-gmail div span{
  
  color: white;
  font-family: Firago-Medium;
  font-size: 24px;
  font-feature-settings: 'case' on;
 }
 .footer-gmail div input{
  border: none;
  border-bottom: 1px solid white;
  color: white;
  background-color: #00A859;
  width: 350px;
  height: 40px;

 }
 .footer-gmail div button{
  width: 250px;
  height: 50px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  font-family: Firago-regular;
  transition: all 0.2s ease;
  color: black;
  background-color: white;
 }
 .footer-gmail div button:hover{
  border: none;
  color: white;
 }

 .bottomFooter{
  position: relative;
  background-color: #04243F;
 }
 .footer-nav{
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
  flex-wrap: wrap;
 }
 .footer-nav div{
  display: flex;
  flex-direction: column;
 }
 .footer-nav div span{
  color: white;
  cursor: pointer;
 }
 .footer-nav div  span a{
  color: white;
  cursor: pointer;
  font-size: 10px !important;
  padding:5px 0  !important;
  display: flex;
  align-items: center;
  font-family: Firago-Regular;
 }





 
 
 .footer-nav div span:hover{
  color: green;
 }
 .footer-nav div span:first-child{
  font-size: 16px;
  font-family: Firago-Regular;
  padding: 15px 0;
  font-feature-settings: 'case' on;
    text-transform: uppercase;
 }
 .footer-nav div span{
  font-size: 10px;
  
  display: flex;
  align-items: center;
  width: fit-content;
  
 }
 .footer-nav div span a {
  font-size: 10px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  color: white;
  
 }
 .footer-nav div span a:visited {
  font-size: 10px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  color: white;
  
 }
 .footer-nav div span a:hover{
  color: green;
  display: flex;
  align-items: center;
 }




 .credits{
  border-top: 1px solid #9A9A9A;
  margin-top: 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
  
 }
 .credits > *{
  flex: 1 1 20vw;
  display: flex;
  justify-content: center;
 }
 .credits div span{
  color: white;
  font-size: 12px;
 }
 .credits div span > a{
  color: #00A859;
 }
 .credits div span > a:visited{
  color: #00A859;
 }




  /* FOOTER END */

  /* LANDING START */
  
  .goToTop svg{
    cursor: pointer;
    font-size: 56px;
    position: absolute;
    right: 10%;
    
    
    bottom: 7%;
    left: 85%;
    color: #00A859 ;
    z-index: 10;
  }
  .landing-items{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

  }
  .landing-items a span{
    font-family: Firago-Bold;
    font-size: 18px;
    font-feature-settings: 'case' on;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }
  a:visited{
    color: black;
  }
  .landing-items a:hover span{
    color: #00A859;
    transition: all 0.5s ease;
  }


  .landing-items .landing-items-info{
    flex: 1 1 13vw;
    text-align: center;
    border-left: 1px solid #BFBFBF;
    
  }
  .landing-items .landing-items-info:first-child{
    border: none;
  }
  .landing-items-header{
    display: flex;
    justify-content: space-between;
    height: 60px;
  }
  .landing-items-header > span{
    font-family: Firago-Bold;
    font-size: 30px;
    font-feature-settings: 'case' on;
    text-transform: uppercase;
  }
  .landing-items-header .seeAll{
    cursor: pointer;
    display: none;
  }
  .landing-items-header .seeAll span a{
    font-family: Nino-Mtavruli-bold;
    font-size: 20px;
    padding-left: 20px;
    text-decoration: none;
    color: black;
  
  }
  a:visited{
    text-decoration: none;
  }

  
  .landing-items-container{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .landing-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    
    
    
  }

  .landing-items-container-infos{
    flex: 0 0 22.5vw;
    background-color: white;
    border: 1px solid #EBEBEB;
    overflow: hidden;

  }
  .landing-items-container-infos img{
    width: 100%;
    height:100%;
    object-fit: cover;
    
    
    overflow: hidden;
  }
  .landing-items-container-infos:hover img{
    transform: scale(1.2);

    transition: all 0.5s ease;
    max-height: 303px;
    overflow: hidden;
  }
  .sakrebulo-landing .landing-items-container-infos:hover img{
    transform: scale(1.05) !important;
  }
  .landing-items-container-infos video{
    width: 100%;
    height: 100%;
  }
  

  .landing-items-container-infos div{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    padding: 0 20px;
    
    
    

  }
  .landing-items-container-infos div:last-child{
    height: auto;
  }
  .landing-items-container-infos div:first-child{
    height: 303px;
    overflow: hidden;
    padding: 0;
    
  }
  .landing-items-container-infos div:first-child img, .landing-items-container-infos div:first-child img:hover{
   transition:  all 0.5s ease;
    
    
  }
  .landing-items-container-infos div:first-child video, .landing-items-container-infos div:first-child video:hover{
    transition:  all 0.5s ease;
     
     
   }

  .landing-items-container-infos div span{
   
    margin: 15px 0;
    padding-left: 10px;
  }
  .landing-items-container-infos div .time{
    color: #727272;
    font-size: 12px;
    font-family: Nino-Mtavruli-bold;

  }
  .landing-items-container-infos div .header{
    font-size: 16px;
    font-family: Firago-Bold;
    color: black;
    vertical-align: middle;
    font-feature-settings: 'case' on;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .landing-items-container-infos:hover div .header{
    color: #00A859 !important;
    transition: all 0.5s ease;
  }
  .vrclad a, .vrclad {
    font-size: 12px;
    color: #1C3951 ;
    font-family: Firago-Bold;
    cursor: pointer;
    font-feature-settings: 'case' on;
  }
  .landing .about{
    margin: 50px 0;
    
    display: flex;
    justify-content:center;

  }
  .landing .about span{
    height: 50px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: Firago-Regular;
    background-color: #BCBCBC;
    color: white;
    border-radius: 30px;
    cursor: pointer;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;
    text-decoration: none;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
  }
  .landing .about span a{
    font-size: 16px;
    font-family: Firago-Regular;
    color: white;
    font-feature-settings: 'case' on;
    text-transform: uppercase;

  }
  .landing .about span::after{
  background-color:#04243F;
  border-radius: 3rem;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: .2s transform ease-out;
  will-change: transform;
  z-index: -1;
  }

  .landing .about span:hover::after{
  transform: translate(0, 0);
  }

  .landing .about span:hover{
    border: 2px solid transparent;
    color: white;
    transform: scale(1.05);
    will-change: transform;
  }


  .footer-gmail div button::after{
    background-color:#00A859;
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: .2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .footer-gmail div button:hover::after{
    transform: translate(0, 0);
  }
 .footer-gmail div button:hover{
  border: 2px solid transparent;
    color: white;
    transform: scale(1.05);
    will-change: transform;
 }
  /* LANDING END */


  /* SASARGEBLO BMULEBI START */
  .sasargebloBmulebi{
    margin-top: 30px;
    
  }
  .sasargebloBmulebi span{
    font-size: 30px;
    font-family: Firago-Medium;
  }
  .bmulebi{
    background-color: white;
    margin: 20px 0;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 530px;
  }
  .bmulebi:last-child{
    margin-bottom: 50px;
  }
  .bmulebi span{
    margin-left: 20px;
    font-size: 18px;
    font-family: Firago-regular;
    font-feature-settings: 'case' on;
  }
  .bmulebi img{
    margin-right: 20px
  }


  /* SASARGEBLO BMULEBI END */


  /* MIWERE MERS START */
  .miwereMers{
    display: flex;
    
    flex-direction: column;
    gap: 20px;
    margin-top: 70px;
  }
  .miwereMers .header{
    font-feature-settings: 'case' on;
  }
  span.header{
    font-size: 30px;
    font-family: FiraGo-Medium;
   height: 100px;
   overflow: hidden;
   line-height: 1.4;

  }
  .miwereMers > div{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    

  }
  .miwereMers-container form{
    flex: 0 0 30vw;
    display: flex;
    flex-direction: column;
  }
  .miwereMers-container img{
    width: 100%;
  }

  .miwereMers-container > form > *{
    margin-bottom: 20px;
  }
  .miwereMers-container input{
    height: 50px;
   
    border-radius: 10px;
    border: 1px solid #DFDFDF ;
  }
  .miwereMers-container span{
    font-size: 16px;
    font-family: Mrgvlovani-regular;
  }
  
    input{
      padding-left: 20px;
      font-size: 14px;
    }
    .miwereMers-info{
      display: flex;
      flex-direction: column;
    }
    .miwereMers-info span{
      font-size: 13px;
      font-family: Mrgvlovani-regular;
    }
    .miwereMers-info .header{
      margin-top: 40px;
      font-size: 18px;
      font-family: Nino-Mtavruli-bold;
    }
    .miwereMers-info > *{
      margin-bottom: 30px;
    }
    .miwereMers .fileUploader span{
      font-size: 14px;
      font-family:  Mrgvlovani-regular;
    }
    textarea{
      padding: 20px;
      border-radius: 10px;
      border: 1px solid  #DFDFDF;
    }
    .contact-us textarea{
      margin-bottom: 0;
    }
    .miwereMers-submit{
      width: 250px;
      height: 50px;
      background-color:#00A859;
      border-radius: 50px;
      color: white;
      border: none;
      cursor: pointer;
      font-size: 16px;
      transition: all 0.4s ease;
    }
    .miwereMers-submit:hover{
      opacity: 80%;
      transition: all 0.4s ease;
    }

    .mb-6{
      display: flex;
      align-items: center;
     
      gap: 30px;

    }

    .formbold-file-input input {
      
      position: absolute;
     opacity: 0;
      
     
    }
  
    .formbold-file-input label {
      position: relative;
      
      
     
      display: flex;
      align-items: center;
      justify-content: center;
      
      text-align: center;
    }
    .formbold-drop-file {
      display: block;
      font-weight: 600;
      color: #07074d;
      font-size: 20px;
      margin-bottom: 8px;
    }
  
    .formbold-or {
      font-weight: 500;
      font-size: 16px;
      color: #6b7280;
      display: block;
      margin-bottom: 8px;
    }
    .formbold-browse {
      font-weight: 500;
      font-size: 16px;
      color: white;
      background-color: #00A859;
      display: inline-block;
      padding: 8px 28px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      cursor: pointer;
     
    }
    .formbold-mb-5{
      cursor: pointer;
    }
    #file{
      cursor: pointer;
      
    }
  /* MIWERE MERS END */


  /* SAJARO INFO START */
  .sajaroInfo p{
    margin: 10px 0;
  }
  .sajaroInfo a{
    color:#00A859;
  }
  .sajaroInfo p:last-of-type{
    margin-bottom: 30px;
  }
    .sajaroInfo div{
      display: flex;
      justify-content: space-between;
     
      background-color: white;
      height:130px;
      margin-bottom: 50px;
    }
    .sajaroInfo div a{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      height:130px;
      margin-bottom: 50px;
      color: black;
    }
    .sajaroInfo div a:visited{
      
      color: black;
    }
    a:visited span, a span{
      color: black;
    }



    .sajaroInfo div:last-child{
      margin-bottom: 100px;
    }
    .sajaroInfo header{
      font-size: 30px;
      font-family: Firago-Medium;
      margin-top: 75px;
      margin-bottom: 40px;
      font-feature-settings: 'case' on;
    }
    .sajaroInfo div span{
      margin-left: 20px;
      font-size: 16px;
      font-family: FiraGo-regular;
      font-feature-settings: 'case' on;
      line-height: 1.3;
    }
    .sajaroInfo div img{
      padding:20px 25px;
      border-left:1px solid #BCBCBC;
      margin-left: 10px;
    }


  /* SAJARO INFO END */

  /* NEWS START */
    
     header {
      margin: 30px 0;
      display: flex;
      align-items: center;
    }
    header span.big{
      font-size: 30px !important;
      font-family: Firago-Medium !important;
      position: relative;
      margin-right: 90px;
      font-feature-settings: 'case' on;
    }

     header span.big::after{
      content: '';
      width: 30%;
      border-bottom: solid 5px #00A859;
      position: absolute;
      left: 110%;
      top: 42%;
      z-index: 1;
    }
    
    header span{
      font-size: 20px !important;
      font-family: Firago-Medium !important;
      font-feature-settings: 'case' on;
    }

    .news-container .landing-container{
      width: 46vw;
      flex-wrap: wrap;
    }
    .news-container .landing-items-container-infos{

      flex: 1 1 40%;
      max-width: 420px;
      margin-bottom: 40px;
      flex-wrap: wrap;
      min-width: 300px;

    }
    .news-container .sakrebulos-siakhleebi .landing-items-container-infos{
      max-height: 500px;
    }
    .videos > a{
      flex: 1 1 40%;
max-width: 420px;
margin-bottom: 40px;
flex-wrap: wrap;
min-width: 300px;
    }

    .news-container > div{
      display: flex;
      justify-content: space-between;
    }
    .presscenter-info{
      display: flex;
      flex-direction: column;
      height: fit-content;
      background-color: white;
      margin-bottom: 50px;
      width: 20vw;
    }
    .presscenter-info > * > a{
    
      padding: 15px 0;
      cursor: pointer;
      font-size: 16px;
      font-family:  Firago-Regular;
      color: black;
      font-feature-settings: 'case' on;
    }
    .presscenter-info > * > a:visited{
    
      padding: 15px 0;
      cursor: pointer;
      font-size: 16px;
      color: black;
      font-family:  Firago-Regular;
    }
    .presscenter-info > *{
      margin-left: 30px;
      padding: 15px 0;
      cursor: pointer;
      font-size: 16px;
      font-family:  Firago-Regular;
      width: fit-content;
    }
    .presscenter-info span:first-child{
      margin-top: 30px;
    }

    .presscenter-info span:last-child{
      margin-bottom: 30px;
    }

    .presscenter-info .active a{
      color:#00A859;
    }
    .presscenter-info .active {
      color:#00A859;
    }
    .news-container .pagination{
      justify-content: left;
    }
    .pagination ul{
      display: flex;
      justify-content: space-between;
      width: 11vw;
      margin-left: 5.5vw;
      margin-bottom: 50px;
    }
    .pagination ul > *{
      padding: 0 5px;
      cursor: pointer;
    }
    .pagination svg{
      color:#00A859;
    }
    .pagination .left-space{
      width: 26vw;
    }
    .pagination li a{
      color: #848484;
      font-size: 15px;
      font-family: Mrgvlovani-regular;
    }
    .pagination li.activeButton a{
      color: black;
    }
    
    /* NEWS1 START */


    .main{
      display: flex;
      justify-content: space-between;
    }

    .main-picture{
      width: 46vw;
      display: flex;
      flex-direction: column;
      background-color: white;
      gap: 30px;
      
      

    }
    .main-picture img{
      width: 100%;
    }
    .main-picture span{
      margin-left: 30px;
    }
    .main-picture span.time{
      font-size: 12px;
      font-family: Nino-Mtavruli-bold;
      color: #727272
    }
    .main-picture span.header{
      font-size: 20px;
      font-family: Firago-Regular;
      
      font-feature-settings: 'case' on;
      white-space: none;
    }
    
    span.header{
      height: fit-content !important;
    }
    .news1-container .main-content{
      width: 46vw;
      display: flex;
     
      flex-direction: column;
      background-color: white;
      
      
      


    }
    .news1-container .main-content img{
     
      
      
      cursor: pointer;
    }

    .news1-container .main-content img:focus{
      left: 50%;
      bottom: 10%;
      transform: translate(-50%);
      position: fixed;
      width: auto;
      height: 600px;
      max-width: 90vw;
      max-height: calc(90% - 200px);
      
      
      
      z-index: 500;
      border-radius: 15px;
    }
    .sakrebulo-landing-paragraphs > span p:not(:first-child) img:focus{
      left: 50%;
      bottom: 10%;
      transform: translate(-50%);
      position: fixed;
      width: auto;
      height: 600px;
      max-width: 90vw;
      max-height: calc(90% - 200px);
      
      
      
      z-index: 500;
      border-radius: 15px;
      cursor: pointer;
    }
    .sakrebulo-landing-paragraphs > span p:not(:first-child) img{
      
      cursor: pointer;
    }

    .news1-container .main-content img:focus .news1-container{
      background-color: black;
      position: absolute;
      width: 100vw;
      height: 100vh;
      
    }
    .main-content-text > div:last-child div{
      display: flex;
      flex-wrap: wrap;
    }
    .main-content-text > div:last-child div img{
      width: 30%;
      height: auto;
    }
    .main-content-text{
      display: flex;
      flex-wrap: wrap;
    }
    .main-content-text > *{
      margin: 15px 0;
    }
    .news1-container .main-content:last-child{
      margin-bottom: 100px;
      
    }
    .main-content span:first-child{
     
      flex-direction: column;
      font-size: 14px;
      font-family: Mrgvlovani-Regular;
    }
    .main-content > span{
      margin: 0 20px 40px 30px;
      font-family: Mrgvlovani-Regular;

    }
    .main-content > span p{
      display: flex;
      flex-wrap: wrap;
    }
    .main-content > span:last-child{
      text-align: right;
      color: #A4A4A4;
    }
    .main-content svg{
      color: #04243F;
      margin: 0 10px;
    }
    .main-content svg:hover{
      color: #04243F;
      cursor: pointer;
    }
    .main-content button{
      width: fit-content;
    }
    .main-content-text > span:last-child{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-feature-settings: 'case' on;
      font-family: Firago-Regular;
      
    }
    .main-content-text p:last-child{
      display: flex;
      flex-wrap: wrap;
      

    }
    .main-content-text p:last-child > img{
      width: 30%;
      height: auto;
    }

    .moreNews{
      display: flex;
      flex-direction: column;
      width: 22vw;
      gap: 30px;
      margin-bottom: 50px;
    }
    .moreNews > span{
      font-size: 20px;
      font-family: Firago-Medium;
      color: #A4A4A4;
      font-feature-settings: 'case' on;
    }
    span a{
      color: #1C3951;
    }
    span a:visited{
      color: #1C3951;
      
    }
    

    /* NEWS1 END */



  /* NEWS END */
  /* ANONSEBI START */
  .anonsebi{
    flex-direction: column !important;
  }
  .anonsebi .landing-items-container-infos{
    max-width: 100%;
    max-height: 230px;
  }
  .anonsebi .landing-items-container-infos > a{
    display: flex;
    height: 100%;
  }
  .anonsebi .landing-items-container-infos div:first-child{
    width: 700px;
    border-right: 1px solid #EBEBEB;
    height: 100%;
  }
  .anonsebi .landing-items-container-infos:hover img{
    transform: none;
    max-height:none;
  }
  

  .anonsebi .landing-items-container-infos div:last-child{
    margin-top: 50px;
  }
  .anonsebi .landing-items-container-infos div .time{
    text-align: end;
    color: #04243F;
  }
  .anonsebi .landing-items-container-infos div .header{
    color: #04243F;
    transition: all 0.5s ease;
  }
  


  /* ANONSEBI END */

  /* CONTACT START */
    .contact{
      overflow: hidden;
    }
    .contact > img {
      width: 100%;
    }
    .contact-us span:first-child{
      margin-top: 30px;
      font-size: 30px;
      font-family: Firago-Medium;
     
    }
    .contact-header-name{
      margin: 30px 0 20px 0;
    }
    .contact-us span{
      display: block;
      margin-bottom: 30px;
      font-size: 18px;
      font-family: Firago-regular;
    }
    .contact-us-halfs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .contact-us-halfs > *{
      flex: 0 0 34vw;
    }
    .contact-form{
      display: flex;
      flex-direction: column;
      
    }
    .contact-form input{
      margin-bottom: 27px;
      border: 1px solid #DBDBDB;
      border-radius: 15px;
      height: 6vh;
    }
    .contact-form > form >  input{
      width: 100%;
      
    }
    .input-half{
      display: flex;
      justify-content: space-between;
    }
    .input-half input{
      width: 45%;
      
    }
    textarea{
      resize: none;
      margin-bottom: 27px;
      width: 100%;
    }
    input.msg{
      height: 15vh;
    }
    .contact svg{
      color:#00A859;
      font-size: 22px;
      margin: 0 20px;
    }
    .contact-location{
    
      display: flex;
      flex-direction: column;
     gap: 30px;
     justify-content:flex-end;
     padding-left: 30px;
    }
    .added-parent{
      display: flex;
      justify-content: space-between;
      background-color: #00A859;
      margin-bottom: 50px;
      gap: 1px;

    }
   
    .contact .contact-location span:first-child{
      margin-top: 0;
      margin-top: 30px;
font-size: 30px;
font-family: Firago-Medium;
    }
    .contact .contact-location span, .contact .contact-location a, .added a, .added span{
      font-size: 18px;
      font-family: Firago-Regular;
      margin-bottom: 5px;
      display: flex;
      color: black;
      align-items: center;
      
    }
    .added{
      display: flex;
      gap: 30px;
      flex-direction: column;
      
      
      
    }
    .added-parent > *{
      flex-basis: 50%;
      background-color: #F6F8F9;
      

    }
    .added span{
      margin: 0;
    }
    .added span:not(:first-child){
      margin-bottom: 5px;
    }
    .added svg{
      margin-left: 0;
    }
    .landing-container iframe{
      width: 420px;
      height: 100%;
      max-width: 85vw;
      
    }
    .contact-buttons{
      margin-bottom: 50px;
      margin-top: 30px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 34vw;
    }
    .contact-buttons button{
      cursor: pointer;
    }
    .contact-buttons button:hover{
      opacity: 0.9;
    }
    
    .contact-buttons div span:first-child{
      margin-top: 0;
      display: inline;
      font-size: 14px;
      font-family: Mrgvlovani-caps;
    }
    .contact-buttons div  svg{
      font-size: 14px;
      color: #04243F;
    }
    button{
      font-feature-settings: 'case' on;
      height: 50px;
      width: 10vw;
      color: white;
      background-color: #00A859;
      border-radius: 20px;
      border: none;
      font-family: Firago-Regular;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    iframe{
      width: 100vw;
      height: 40vh;
      border: none;
    }
    .video-wrapper  iframe {
      max-width: 100%;
      width: 100%;
    }



  /* CONTACT END */

  /* Sakrebulo Members START */

  .sakrebulo-landing .landing-container .landing-items-container-infos {
    flex: 1 1 14vw;
    margin-bottom: 40px;
    border-radius: 20px;
    min-width: 220px;
  }
  .sakrebulo-landing .landing-container .landing-items-container-infos .header{
    height: fit-content;

  }
  .sakrebulo-landing .landing-container .landing-items-container-infos span{
    font-size: 14px;
    font-family: Firago-Medium;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    color: black;
  }
  .sakrebulo-landing .landing-container .landing-items-container-infos span.header{
    font-size: 16px;
    font-family: Firago-Regular;
  }
  span.norm{
    position: relative;
    margin-right: 80px;
  }
  span.samsakhurebi-norm{
    margin-right: 75px;
  }
  span.norm::after{
    content: '';
    width: 30%;
    max-width: 30px;
    border-bottom: solid 5px #00A859;
    position: absolute;
    left: 110%;
    top: 40%;
    z-index: 1;
  }
  .sakrebulo1{
    background-color: white;
    margin-bottom: 150px;
    position: relative;
    padding-bottom: 150px;
  }
  .sakrebulo1 .image-wrapper{
    display: flex;
    gap: 5px;
    
    

  }
  .sakrebulo-landing-near-image{
    display: flex;
    flex-direction: column;
    gap: 10px;
    
  }
  .sakrebulo-landing-near-image > div{
    flex-wrap: wrap;
   
  }
  .sakrebulo-landing-near-image > div > div{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .socials a >svg{
    color: #3D87AF;
  }
  

  .sakrebulo-landing-near-image .socials svg{
    font-size: 19px;
    margin: 0;
    margin: 0 10px !important;
    transition: 0.4s ease;
    
  }
  .sakrebulo-landing-near-image .socials svg:hover{
    color: #333;
    transition: 0.4s ease;
  }
  .sakrebulo-landing-near-image .socials a:first-child svg:hover{
    color: #4267B2;
  }
  .sakrebulo-landing-near-image .socials a:nth-child(3) svg:hover{
    color: #1DA1F2;
  }
  .sakrebulo-landing-near-image .socials a:nth-child(2) svg:hover{
    color:black;
  }
  .sakrebulo-landing-near-image .socials a{
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .sakrebulo-landing-near-image .socials a:nth-child(2){
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
  }
  .sakrebulo-landing-near-image .socials{
    
    
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 200px;
    background-color: #F6F8F9;
    gap: 10px;
  }

  .sakrebulo-landing-near-image h3{
    
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 19px;
    font-family: Firago-Bold;
    font-weight: 500;
    font-feature-settings: 'case' on;
  }
  .sakrebulo-landing-near-image > span{
    font-feature-settings: 'case' on;
    color:#707070;
    font-family: Firago-Regular;
    
  }
  .sakrebulo-landing-near-image > div{
   
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .sakrebulo-landing-near-image > div span:first-child{
    padding-right: 20px;
    border-right: 1px solid #707070;
  }
  .sakrebulo-landing-near-image > div span, .sakrebulo-landing-near-image > div a{
    font-family: Firago-Regular;
    color:#707070;
    margin: 15px 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    
    

  }
  .defaultSkhdoma header > span{
    font-size: 12px !important;
  }
  
  .sakrebulo-landing-near-image .languages{
    display: none;
    margin-top: 100px;
  }
  .sakrebulo-landing-near-image > div svg{
    margin: 0 10px;
    transition: 0.4s ease;
  }
  .sakrebulo-landing-near-image > div svg:hover{
    color: #333;
    transition: 0.4s ease;
  }
  .sakrebulo-landing-near-image > div svg:first-child{
    margin-left: 0;
  }
  .sakrebulo-landing-near-image > div div:nth-child(2) svg{
    color: #3D87AF;
  }
  .sakrebulo1 svg{
    color: #7E7E7E;
  }

  .sakrebulo1 .landing-container img{
    
    width: 280px;
    height: auto;
    object-fit: contain;
    max-height: 240px;
    
  }
  .sakrebulo-landing-paragraphs > span{
    display: block;
    margin: 30px 20px;
    font-size: 14px;
    font-family: Mrgvlovani-regular;
    color: black;
  }
  .sakrebulo-landing-paragraphs > span img{
    max-width: 100%;
  }
  
  
  .sakrebulo-landing-paragraphs > span *{
    font-family: inherit;
    font-size: inherit;
  }
   
  .samsakhurebi1 .sakrebulo-landing-paragraphs > span p:first-child, .samsakhurebi1 .sakrebulo-landing-paragraphs > span p:nth-child(2){
    display: none;
  } 

  .share{
    position: absolute;
    padding: 30px 30px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .share span{
    font-size: 14px;
    font-family: Mrgvlovani-caps;
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .share button{
    width: fit-content;
    height: fit-content;
  }
  .share span svg{
    margin: 0 20px;
    cursor: pointer;
    
  }
  .share span svg:hover{
    color: #333;
  }
  .share span.back{
    cursor: pointer;
  }
  .share span.back svg{
    color: white;
    background-color: #04243F;
    border-radius: 50%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .share .back div{
    width: 45px;
    height: 45px;
    margin-right: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease-in;
  }
  .share .back div:hover{
    background-color: #00A859;
    transition: .5s ease-in;
  }
  
  /* SAKREBULO members END */

  /* SAKREBULO KOMISIEBI START */
  .landing-container-komisia{
    flex: 1 1 48%;
    max-width: 48%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 50px;
  }
  .landing-container-komisia a > span{
    margin: 0 20px;
  }
  .landing-container-komisia div {
    margin: 0 20px;
  }
  .landing-container-komisia a{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .landing-container-komisia a span{
    color: black;
  }
  .landing-container-komisia img{
    width: 100%;
  }
  .landing-container-komisia svg{
    color:#423c3c;
    margin: 0 20px;
  }
  .landing-container-komisia  .name{
    font-size: 16px;
    font-family: Firago-Medium;
  }
  .landing-container-komisia span{
    font-size: 14px;
    font-family: Firago-Regular;

  }
  .landing-container-komisia  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    gap: 20px;
    height: 50px;
    margin-bottom: 20px;
    
  }
  .landing-container-komisia  div > *{
    flex: 1;
  }
  .landing-container-komisia  div > *:last-child{
    border-left: 1px solid #7E7E7E;
    font-size: 12px;
    
  }
  .landing-container-komisia  div span{
    display: flex;
    
    align-items: center;
    height: 100%;
    color: #7E7E7E;
    
  }
  .komisia{
    display: flex;
    background-color: white;
    border-radius: 10px;
        width: 100%;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 100px;
  }
  .komisia img{
    max-width: 300px;
  }
  
  
  .komisia > *:first-child{
    
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    width: 60%;
    min-width: 300px;
  }
  .komisia > div > span:first-child{
    margin-top: 1px;
    font-size: 16px;
    font-family: Firago-Medium;
  }
  .komisia span{
    font-size: 14px;
    font-family: Firago-Regular;
  }
  .komisia .half{
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

  }
  
  .landing-container-komisia .half1 {
    gap: 0;
  }
 
  .komisia .half svg{
    margin: 0 10px;
    color:  #7E7E7E;
  }
  .komisia .half span{
    flex: 1;
    display: flex;
    
    align-items: center;
    height: 100%;
    margin-right: 10px;
    color:  #7E7E7E;
  }
  .komisia .half span:last-child{
    border-left: 1px solid #7E7E7E;
  }
  .komisia header{
    margin: 15px 0;
    font-size: 16px;
    font-family: Firago-Medium;
  }
  .komisia > div >  span:last-child{
    margin-bottom: 50px;
  }
 


  /* SAKREBULO KOMISIEBI END */

  /* FRAQCIEBI START */
  .fraqciebi{
    width: 100%;
  }
  .fraqciebi ol li{
    list-style: decimal;
  }
  
  .fraqciebi > span{
    font-size: 20px;
    font-family: Firago-Medium;
    
  }
  .fraqciebi .list{
    background-color: white;
    margin: 35px 0 45px 0;
    padding: 20px 30px;
    
  }
  .fraqciebi .list ol{
    padding-left: 20px;
  }
 
  .fraqciebi ol li,  .fraqciebi ul li{
    font-size: 20px;
    font-family: Firago-Regular;
    color: #707070;
    margin: 10px 0;
  }
  .fraqciebi ul li{
    padding-left: 30px;
    list-style:square;
    list-style: inside;
    
  }
  

  /* FRAQCIEBI END */

/* WEVRTA SIA START */
  .wevri{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 30px 25px;
    padding-right: 0;
    height: 135px;
    margin: 10px 0;
  }
  .wevri svg{
    color: #7E7E7E;
    margin: 0 30px;
    
  }
  .wevri > *{
    
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
   
  }
  .wevri > .middle{
    padding: 0 45px;
  }
  .wevri > *:last-child span{
    display: flex;
    justify-content: center;
  }
  
  
  .wevri > *:first-child span:first-child{
    font-size: 16px;
    font-family: Firago-Medium;

  }
  .wevri > *:first-child > .green{
    color: #00A859;
    margin: 10px 0;
  }
  .wevri > * span{
    font-size: 14px;
    font-family: Firago-Regular;
  }
  .wevri .middle{
    border-right: 1px solid #E1E1E1;
    border-left: 1px solid #E1E1E1;
  }
  .middle span{
    color: #414141;
  }
  .wevri > *:last-child > *:last-child{
    color: #7E7E7E;
  }


/* WEVRTA SIA END */


/* SAKREBULOS BIURO START */



/* SAKREBULOS BIURO END */

/* SAMARTLEBRIVI AQTEBI START */
  .wrapper{
    background-color: white;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin: 15px 0;

}
.wrapper a{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  
}
.wrapper a span{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-family: Firago-Medium;
  color: black;
}
  .wrapper svg{
    color: #00A859;
    height: 27px;
  }
  .wrapper:hover svg{
    color:white;
    transition: all 0.3s ease-out;
  }
  .wrapper:hover {
    background-color: #00A859;
    transition: all 0.3s ease-out;
  }
  .wrapper:hover a span{
    color: white;
    transition: all 0.3s ease-out;
  }

/* SAMARTLEBRIVI AQTEBI END */

/* MERIA SAMSAKHREBI start */
  #meria{
    margin-right: 50px;
  }
  .meria{
    display: flex;
    width: 100%;
    background-color: white;
   
    border: 1px solid #EBEBEB;
    height: 300px;
    cursor: pointer;
 
    
  }
  .margin-bm-30{
    margin-bottom: 30px;
    width: 100%;
  }
  .margin-bm-30 > div > div > div > a > svg{
    color: #3D87AF;
  }
  .sakrebulo-landing-near-image > div > a > svg{
    color: #3D87AF;
  }
  .meria > div > div > div:nth-child(1) >  a  > svg{
    color: #3D87AF;
  }
  .margin-bm-30 a svg{
    color: #3D87AF;
  }
  .margin-bm-30 img:hover,.margin-bm-30 img {
    transition: none !important;
    transform: none !important;
  }




  .meria-More{
    margin-top: auto;
    font-family: Firago-Regular;
    font-size: 15px !important;
    transition: all 0.4s ease;
    text-align: end;
    
  }
  .meria:hover .meria-More{
    color: #00A859;
    transition: all 0.4s ease;
  }
  
  .meria:hover img{
    transition: all 0.4s ease;
    object-fit: cover;
   transform: scale(1.2);
    
  }
  .meria:hover span:first-child{
    color: #00A859;
    transition: all 0.4s ease;
  }
  .meria > div:first-child{
    
    width:auto !important;
    max-width: 80vw;
    min-width: 200px;
    height: 100%;
    overflow: hidden;
  }
  .meria > div > div{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    
    gap: 20px;
    font-size: 13px;
  }
  .meria > div > div span, .meria > div > div a{
    font-family: Firago-Regular;
    color:#707070;
    margin: 5px 0;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: fit-content;

  }
  .socials{
    display: flex;
    gap: 10px;
    background-color: #F6F8F9;
    width: fit-content;
    padding: 0 10px;
    
  }
  
  .meria svg{
    color: #707070;
  }
  .bordered{
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
    margin: 10px 0;
  }
  .bordered svg{
    margin: 0 10px;
  }
  .meria .socials a{
    padding: 0;
    margin: 15px 0;
  }
  .meria span:first-child{
    font-size: 16px;
    transition: all 0.4s ease;
  }
  .meria span{
    
    font-size: 14px;
    font-family: Firago-Regular;
    line-height: 1.2;
    font-feature-settings: 'case' on;
  }

  .meria > div:not(:first-child){
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
  }

  /* MODAL   */
  .modal_container{
    width: 29.1vw;
    background-color: white;
    padding: 20px 30px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  .modal_container img{
    cursor: pointer;
  }
  .modal_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    
  }
  .modal_header > span{
    color: #00A859;
    font-size: 18px;
    font-family: Firago-Medium;
  }
  .modal_body{
    display: flex;
    flex-direction: column;
  }
  .modal_body > span{
    margin-bottom: 13px;
    font-size: 14px;
    font-family: Firago-Regular;
  }
  



  



/* MERIA SAMSAKHUREBI end */

/* MERIS WARMOMADGENLEBI START */
.meria img{
  height: 100% !important;
 
  width: 100%;
  object-fit: cover;
  transition: all 0.4s ease;
}
  .samsakhurebi{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    height: 168px;
    padding: 20px 30px;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    
  }
  .samsakhurebi header{
    margin: 0;
    font-size: 16px;
    font-family: Firago-Medium;
    font-feature-settings: 'case' on;
    
  }
  .samsakhurebi > span{
    margin: 20px 0;
    font-size: 14px;
    font-family: Firago-Regular;
    font-feature-settings: 'case' on;
  }
  .samsakhurebi svg{
    color: #707070;
    margin: 0 10px;
    margin-left: 0;
  }
  .samsakhurebi div{
    display: flex;
    justify-content: left;
    align-items: flex-start;
  }
  .samsakhurebi div span, .samsakhurebi div a{
    color: #7E7E7E;
    font-size: 14px;
    font-family: Firago-Regular;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 40px 5px 0;

  }
  .samsakhurebi div span:last-child{
    padding: 5px 0 5px 40px;
    border-left: 1px solid #E1E1E1;
  }
  
   .warmomadgenlebiModal{
    height: fit-content;
    width: 560px;
    display: none !important;
    position: absolute;
    top: 20%;
    left: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
    flex-direction: column;
    background-color: white;
    color:#00A859;
    cursor:default;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 500;
    transform: translate(-50%, 0);
  }
  .show{
    display: flex !important;
  }

  
  .samsakhurebi .warmomadgenlebiModal span{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    border: none !important;
    color:#00A859;
    font-size: 18px;
    font-family: Firago-Medium;
    align-items: flex-start;
  }
  .samsakhurebi .warmomadgenlebiModal span:first-child{
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .samsakhurebi .warmomadgenlebiModal svg{
    color: black;
    cursor: pointer;
    margin: 20px;
    font-size: 30px;
  }

  .warmomadgenlebiModal span{
    padding: 5px 0 !important;
  }
  .warmomadgenlebiModal span p{
    color:#000000;
    font-size: 16px;
    font-family: Firago-Regular;
    line-height: 1.3;
   
  }

/* MERIS WARMOMADGENLEBI END */


/* KHULOS ISTORIA START */
.flex{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.istoria{
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 250px;
  width: 100%;
}

 .istoria .image-wrapper{
    width: 100%;
  }
  .istoria .image-wrapper img{
    width: 100%;
  }
  .istoria .sakrebulo-landing-paragraphs > span{
   margin: 30px;
    margin-top: 10px;
   font-size: 14px;
   font-family: Firago-Regular;
   line-height: 1.5;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
 
  .istoria .sakrebulo-landing-paragraphs > span p{
    margin-bottom: 30px;
  }
  .istoria .share{
    position: relative;
    margin: 0 30px;
    width: auto;
    padding: 40px 0;
    padding-top: 100px;
  }
  .share  span:last-child svg{
    color:#333 !important;
  }
  .istoria .share .back div{
    box-shadow: 1px 2px 1px 2px #EBEBEB;
  }
  .istoria .share span.back{
    width: 195px;
  }
  .istoria .share .back div{
    margin-right: 15px;
  }

/* KHULOS ISTORIA END */

/* GENDERULI TANASWOROBIS SABCHO START */
  .news-container header span.genderuli::after{
    width: 10%;
  }
  .landing-container .oqmebi-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .landing-container .oqmebi-container > span{
    margin-bottom: 20px;
    width: 100%;
    height: 70px;
    background-color: #00A859;
    font-size: 20px;
    font-family: Firago-Bold;
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: white;
  }
  .oqmebi-info{
    background-color: white;
    padding: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .oqmebi-info:last-child{
    margin-bottom: 65px;
  }
  .oqmebi-info span{
    border-right: 1px solid #E1E1E1;
    padding: 5px 0;
    font-size: 14px;
    font-family: Firago-Regular;
    line-height: 1.5;
  }
  .oqmebi-info img{
    margin-left: 30px;
    width: 35px;
    height: 35px;
  }

/* GENDERULI TANASWROBOIS SABCHO END */

/* Video galerea START */
.landing-items-container-infos video{
  width: 100%;
  cursor: pointer;

}
.landing-items-container-infos .video-wrapper{
  width: 100%;
  padding: 0;
  position: relative;
}
.video-wrapper svg{
  color: green;
  background-color: white;
}


/* Video galera end */



/* Monawileobrivi Biujeti start */

    .monawileobrivi-biujeti-container{
      padding: 40px;
      padding-bottom: 25px;
      background-color: white;
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 70px;
    }
    .monawileobrivi-biujeti-container > span{
      font-size: 14px;
      font-family: Mrgvlovani-regular;
    }
    .pdf-container{
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 60px;
    }
    .pdf{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border: 2px solid #E1E1E1;
      border-radius: 10px;
      padding: 0 30px;
    }
    .pdf span{
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Mrgvlovani-regular;
      margin-right: 30px;
      border-right: 2px solid #E1E1E1;
      height: 100%;
      padding-right: 20px;
      
      
    }
    .monawileobrivi-biujeti-container .share{
      position: static;
      padding: 0;
    }
    .share .back div{
      box-shadow: 1px 2px 1px 2px #EBEBEB;
    }
    .monawileobrivi-biujeti-container svg{
      color: #707070;
    }


/* monawileobrivi biujeti end */


/* GANKARGULEBEBI START */
.landing-container .sajaroInfo{
  width: 100%;
}



/* GANKARGULEBEBI END */

/* moqalaqis portali START */
  #MoqalaqisPortali{
    margin-right: 160px;
  }
  #meria{
    margin-right: 50px;
  }


  .half1 span:first-child{
    padding-right: 5px;
  }

/* moqalaqis portali end */




/* MEDIA QUERIES */
@media only screen and (max-width: 1700px){
  header span.big{
    font-size: 24px !important;
  }
  .input-half{
    flex-direction: column;
  }
  .input-half > input{
    width: 100%;
  }
}
@media only screen and (max-width: 1600px){
  .half1 span:first-child{
    font-size: 12px;
  }
  .half1 svg{
    font-size: 12px;
    margin: 0 10px;
  }
  
  .meria > div > div span, .meria > div > div a{
    font-size: 10px;
    display: flex;
    align-items: center;
  }
  .margin-bm-30 .meria svg{
    font-size: 12px;
  }
}

@media only screen and (max-width: 1400px) {
  .hotline span{
    font-size: 12px;
  }
  .bottomNav-contact span a{
    font-size: 12px;
  }
  .dropbtn{
    font-size: 12px;
  }
  .topNav img{
    height: 80px;
  }
  .goToTop svg{
    left: 90%;
  }
  .padding-280px{
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .margin-280px{
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .landing-items-container-infos{
    flex: 1 1 28vw;
  }
  .landing-container{
    gap: 30px;
  }
  .footer-gmail{
    width: 90%;
  }
  .news-container .landing-container{
    
    overflow: hidden;
  }
  .news-container .landing-container{
    width: 68vw;
  }
  .main-picture{
    width: 55vw;
  }
  .news1-container .main-content{
    width: 55vw;
  }
  
  .moreNews{
    width: 26vw;
  }
  .contact-buttons{
    width: 55vw;
    
  }
  .contact-buttons button{
    width: 15vw;
    min-width: 150px;
  }
  .contact-buttons button:hover{
    opacity: 0.8;
  }
  nav.padding-280px{
    height: 155px;
    
  }

  .bottomNav{
    height: auto;
  }
  .header-wrapper{
    height: 155px;
  }
  .landing-items{
    margin-top: 50px;
  }



  
}

@media only screen and (max-width: 1200px){
  .contact-form{
    flex: 1 1 35vw;
  }
  .contact-location{
    flex: 0 0 30vw;
  }
  .dropbtn{
    font-size: 14px;
  }
  .hotline span{
    font-size: 14px;
  }
  .bottomNav-contact span{
    font-size: 14px;
  }
  .footer-nav div span:first-child{
    font-size: 14px;
  }
  .presscenter-info{
    display: none;

  }
  .news-container .landing-container{
    width: 90vw;
  }
  .sakrebulo-landing .landing-container .landing-items-container-infos{
    flex:  1 1 30%;
  }

}
@media only screen and (max-width: 1024px){
  .main-content-text > div:last-child div{
    flex-direction: column;
  }
  .main-content-text > div:last-child div img{
    width: 70%;
  }
  .main-content-text p:last-child > img{
    width: 70%;
    height: auto;
  }
  .main-content-text p:last-child{
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  span.hide-small{
    display: none;
  }
  .contact svg{
    margin-left: 0;
  }
}


@media only screen and (max-width: 1000px){
  .landing-container-komisia{
    max-width: 100%;
  }
  .contact-us-halfs{
    flex-direction:column-reverse;
  }
  .contact .contact-location span:first-child , .contact-header-name{
   
  }
  .added-parent{
    flex-direction: column;
    gap: 0;
  }
 

  .landing-items-container-infos img{
    
    object-fit: cover;
  }
 
 





  .topNav img{
    height:auto;
  }
  .topNav{
    height: 50px;
  }
  .contact-location{
    
    padding-left: 0;
  }
  .contact .contact-location span:first-child{
    margin-bottom: 20px;
  }
  .footer-nav div{
    margin-bottom: 20px;
    margin-right: 30px;
  }
  .goToTop svg{
    bottom: 13%;
  }
  .goToTop svg{
    left: 80%;
  }
  .credits img{
    margin-right: 10px;
    max-width: 100%;
    min-width: 150px;
  }
  .topFooter{
    gap: 30px;
  }
  .topFooter div{
    flex: 1 1 30vw;
  }
  .footer-gmail{
    flex-direction: column;
    gap: 30px;
    height: fit-content;
    top: -5%;
    border-radius: 10px;
  }
  .footer-gmail:first-child{
    padding: 5px;
  }
  .landing-container{
    gap: 30px;
  }
  .topNav-socials{
    padding-right: 2vw;
  }
  .topNav img{
    max-width: 30vw;
   
  }
  .bottomNav{
    display: none;
  }
  .wrapperr{
    display: block;
  }
  
  @media only screen and (max-width: 700px){
    .meria-More{
      text-align: start;
    }
    #nomargin{
      margin-top: 0 !important;
    }
    .meria > div > div{
      flex-wrap: wrap;
      gap: 10px;
      
    }
    .margin-bm-30 .meria svg{
      font-size: 15px;
    }
    .meria > div > div span, .meria > div > div a{
      font-size: 15px;
    }

    *{
      line-height: 1.5;
    }
    .meria{
      height: fit-content;
    }
    .meria > div:first-child{
      max-width: 100% !important;
      width: 100% !important;
    }
    .sakrebulo-landing-near-image > div{
      flex-direction: column;
      
      gap: 20px;
    }
    .sakrebulo-landing-near-image > div span, .sakrebulo-landing-near-image > div a{
      font-size: 20px !important;
      align-items: center;
    }
    .samsakhurebi1 .sakrebulo-landing-near-image > div > div:last-child{
      flex-direction: column;
    }
    .sakrebulo-landing-near-image > div span{
      margin:0;
      margin-top: 10px;
    }
    header span.big::after{
      top: 35%;
    }
    #meria{
      margin-right: 30px;
    }
    .news-container span.members1{
      margin-right: 50px;
      text-align: center;
    }
   
    .miwereMers > div{
      flex-direction: column;
    }
    .landing-container-komisia{
      flex: 1 1 100%;
    }
    .komisia{
      flex-direction: column-reverse;
      
    }
    .komisia img{
      width: 100%;
      max-width: 100%;
      
    }
    .komisia > :first-child{
      width: 100%;
    }
    .fraqciebi .list{
      padding: 5px 2px;
    }
    .pagination{
      display: flex;
      justify-content: center !important;
    }
    .paginationButtons{
      width: fit-content !important;
    }
    .left-space{
      display: none;
    }
    .topNav-items{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .topNav-socials{
      width: fit-content;
      gap: 5px;
    }
    .topNav input{
      font-size: 10px;
      padding-left: 5px;
    }
    svg{
      font-size: 15px;
    }
    .topNav-socials a:last-child svg{
      font-size: 16px;
    }
    .landing-items{
      margin-top: 50px;
      flex-direction: column;
      
    }
    .landing-items .landing-items-info {
      border: none;

    }
    .landing-items-header > span{
      font-size: 20px;
    }
    nav.padding-280px{
      height: 100px;
    }
    .header-wrapper{
      height: 100px;
    }
    .bmulebi{
      width: 90vw;
    }
    .bmulebi span{
      margin: 0 20px;
    }
    .sajaroInfo div span{
      font-size: 10px;
    }
    header span.big{
      font-size: 13px !important;
      margin-right: 45px;
    }

    header span{
      font-size: 10px !important;
    }
    .defaultSkhdoma header > span{
      font-size: 10px !important;
      
    }
    .defaultSkhdoma header > span:first-child{
      text-align: center;
    }
    .container-text{
      width: 60vw !important;
    }
    .carouselText span {
      font-size: 20px !important;
    }
    .slick-slide img{
      height: 780px !important;
      
    }
    .landing-items-container-infos{
flex: 1 1 45vw;
    }
    .istoria .share{
      margin: 0 5px;
    }
    .share span{
      font-size: 10px;
    }
    span.back{
      margin-right: 20px;
    }
    .sakrebulo1 .image-wrapper{
      flex-direction: column;
    }
    .sakrebulo1 .image-wrapper img{
      width: 100%;
    }
    .meria{
      flex-direction: column;
    }
    .meria img{
      max-width: 100%;
    }
    .samsakhurebi div{
      flex-direction: column;
    }
    .samsakhurebi div span:last-child{
      border: none;
      padding: 10px 0;
    }
    .samsakhurebi{
      height: fit-content;
    }
    .sakrebulo-landing-near-image .languages{
      margin: 0;
    }
    .sakrebulo1 .image-wrapper{
      
      margin-left: 20px;
      width: 100%;
    }
    .wevri{
      flex-direction: column;
    }
    .wevri .middle{
      border: none;
    }
    .sakrebulo-landing .landing-container .landing-items-container-infos{
      flex: 1 1 80vw;
    }
    .moreNews{
      display: none !important;
    }
    .main,.main-content,.main-content-text,.main-picture{
      width: 100%;
      background-color: white;
    }
    .news1-container .main-content:last-child{
      width: 90vw;

    }
    .main-content > span p{
      flex-direction: column;
    }
    
    
    
  }

  @media only screen and (max-width: 500px){
    .hover{
      display: none;
    }
   
  }
}
.wrapperr nav{
  height: auto;
}
nav .m-menu__checkbox {
  display: none;
}
nav label.m-menu__toggle {
  cursor: pointer;
}
nav .m-menu {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 450px;
  width: calc(100vw - 30px);
  height: 100%;
  -moz-transform: translate3d(-450px, 0, 0);
  -o-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  -webkit-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0);
  -moz-transition: transform 0.35s;
  -o-transition: transform 0.35s;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
}
nav .m-menu__overlay {
  background-color: rgba(103, 103, 103, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 1;
  display: none;
}
nav .m-menu__header {
  padding: 0 16px;
  height: 50px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
nav .m-menu__header span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
nav .m-menu .m-menu {
  -moz-transform: translate3d(480px, 0, 0);
  -o-transform: translate3d(480px, 0, 0);
  -ms-transform: translate3d(480px, 0, 0);
  -webkit-transform: translate3d(480px, 0, 0);
  transform: translate3d(480px, 0, 0);
}
nav .m-menu ul {
  
  overflow-y: auto;
}
nav .m-menu ul li a, nav .m-menu ul li label {
  display: block;
  text-align: left;
  padding: 0 15px;
  line-height: 30px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  font-size: 13px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}
nav .m-menu ul li label.a-label__chevron::after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-color: #333;
  border-style: solid;
  border-width: 1px 1px 0 0;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 50%;
  margin-top: -5px;
  right: 16px;
}
nav .m-menu ul li .-invisible {
  border-bottom: 0;
}
nav .m-menu .m-menu label.m-menu__toggle {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
nav .m-menu__checkbox:checked ~ .m-menu__overlay {
  display: block;
  
}
nav .m-menu__checkbox:checked ~ .m-menu {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: #F6F8F9;
}

.wrapperr {
  width: 480px;
  height: 667px;
  overflow: hidden;
  background-color:transparent;
  position: relative;
}
nav .m-menu ul li a{
  color: black;
}
nav .m-menu ul li label.a-label__chevron:hover,nav .m-menu ul li label.a-label__chevron:active{
  background-color: #00A859;
  transition: 0.3s all ease;
}
nav .m-menu ul li label:hover,nav .m-menu ul li label:active{
  background-color: #00A859;
  transition: 0.3s all ease;
}



/*  SLIDERRR*/
.noned{
  display: none !important;
}
 .content  , .content iframe{
  width: 100% !important;
  height: 780px !important;
 
  
}

.slick-active video, .slick-active iframe{
  z-index: 99;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.full, .slick-slide > div{
  height: 100% !important;
}
 .slick-active iframe{
  z-index: -10;
 }
.content .video-stream{
  width: auto !important;
  left: 0 !important;
}
.content{
  position: relative;
}
.slider_image{
  object-fit: contain;
}
.slick-slide{
 
  height: 780px !important;
}
.slick-dots li button{
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
  background-color: #00A859 !important;
}
.slick-dots li  button::before{
  opacity: 0 !important;
   
 }
.slick-dots li.slick-active button::before,.slick-dots li.slick-active button:hover{
  color: white !important;
  opacity: 1 !important;
}

.slick-dots{
  bottom: 50px !important;
  z-index: 30;
}

.carouselText{
  
  position:absolute;
  bottom: 200px;
  color: white;
  width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index:130;
}
.carouselText span{
  
  font-family: Firago-Regular;
  font-size: 28px;
  color: white;
  z-index: 5;
  
  font-feature-settings: 'case' on;
  z-index:130 !important;
}
.slick-slide button{
  position: absolute;
  width: 180px;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #707070;
  bottom: 0px;
 
  opacity: 0;
  
  
 
}
.slick-slide button a{
  
  display: none;
}
.slick-active button{
  bottom: 120px;
  transition: bottom 3s ease;
  
  opacity: 1;
  
  z-index: 230 !important;
}
.slick-active button a{
  z-index: 300 !important;
  display: block !important;
}

.slick-slide button:hover{
  transition: all 0.5s ease;
  background-color:#00A859;
}
.slick-slide button a{
  font-feature-settings: 'case' on;
  color: white;
  font-family: Firago-Regular;
}
.slick-next, .slick-next:focus, .slick-next:hover, .slick-prev, .slick-prev:focus, .slick-prev:hover{
  display: none !important;
}
.content img{
  transform: scale(1.3)!important;
  transition: all 2s cubic-bezier(0.7, 0, 0.3, 1) !important;
  opacity: 1;
}
.slick-active div img{
  transform: scale(1.3)!important;
  transition: all 2s cubic-bezier(0.7, 0, 0.3, 1)!important;
  opacity: 1;
 
}
.slick-slide img{
  transform: scale(1.3)!important;
  transition: all 2s cubic-bezier(0.7, 0, 0.3, 1)!important;
  opacity: 0;
  
}
.slick-slide .carouselText{
  bottom: 250px !important;
  opacity: 0 !important;
  transition: all 1.5s ease!important;
}
.slick-active div .carouselText{
  bottom: 200px !important;
  opacity: 1 !important;
  transition: all 1.5s ease!important;
  display: block;
}
div.slick-slide{
  transition: all 2s cubic-bezier(0.7, 0, 0.3, 1)!important;
}
/*  SLIDERRR COPIED FROM slider.css*/


/* SLIDER FROM Slider.css*/

@media screen and (max-width: 700px){
  .news1-container .main-content img:focus{
    height: 400px;
  }
  
 
}






.container-dots {
  position: absolute;
  top: 700px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background:#00A859 ;
}
  .container-text{
    padding-top: 395px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    
  }
  .test{
    position: absolute;
    padding-top: 250px;
    color: red;
    width: 30vw;

  }
.container-text span{
  font-family: Firago-Regular;
  font-size: 30px;
  color: white;
  z-index: 5;
  position: relative;
  font-feature-settings: 'case' on;
}
.container-text button{
  margin-top: 20px;
 
  border-radius: 50px;
  border: none;
  width: 190px;
  font-family: Firago-Medium;
  font-size: 16px;
  color: white;
  z-index: 5;
  position: relative;
  transition: all 1.5s ease;
  background-color: #78818A;
}

.container-text button:hover{
  color: white;
  background-color: #00A859;
}
.image-gradient{
  opacity: 70%;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
   background-image: linear-gradient(#04243F, #04243F);
   z-index: 20;

}

.years--filter__container{
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}
.years--filter__container li{
  padding: 10px 20px;
  border: 1px solid #04243F;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent;
}
.years--filter__container li:hover,
.years--filter__container li.active{
  color: white;
  background-color: #04243F;
}
.years--itemContainer div:last-child{
  margin-bottom: 50px;
}
.years--itemContainer:not(.active){
  display: none;
}

@media only screen and (max-width: 1200px){
  .container-text span{
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px){
  strong{
    text-align: center;
    display: block;
    max-width: 100%;
  }
  .container-text span{
    font-size: 20px;
  }
  .container-text button{
    width: 150px;
  }
  .slide{
    height: 600px;
  }
  .container-text{
    padding-top: 320px;
  }
  .container-dots{
    top: 550px;
  }
  
}
@media only screen and (max-width: 500px){
  .slide{
    height: 500px;
  }
  .container-text{
    padding-top: 160px;
  }
  .container-dots{
    top: 440px;
  }
  .container-text span{
    font-size: 17px;
  }
  .news1-container .main-content img:focus{
    height: 200px;
  }

}


/*
.slider {
  box-shadow: 0 20px 50px -25px rgba(0, 0, 0, 1)!important;
}
*/





/* aqedan 
.slick-track {
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) !important;
}
.slick-track .slick-active img {
  transform: scale(1)!important;
}


.slick-slide {
  height: 100%!important;
  position: relative!important;
  z-index: 1!important;
  transition: all 3s cubic-bezier(0.7, 0, 0.3, 1)!important;
} 
.slick-slide  img {
    width: 100%;
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1)!important;
    transform: scale(1.3)!important;
  }
  .slick-slide .carouselText{
    transition: all 3s cubic-bezier(0.7, 0, 0.3, 1)!important;
    opacity: 0;
    bottom: 250px;
  }
   
  
  
  

.slick-active  .carouselText{
  transition: all 3s cubic-bezier(0.7, 0, 0.3, 1)!important;
  opacity: 1;
  bottom: 200px;
}

*/



/*          video player styling       */


.rh5v-Seek_track, .rh5v-Seek-input{
  display: none !important;
}


@media only screen and (max-width: 600px){
  .anonsebi .landing-items-container-infos{
    max-height: none;
   
  }
  .anonsebi .landing-items-container-infos > a{
    flex-direction: column;
  }
  .anonsebi .landing-items-container-infos div:first-child{
    width: 100%;
  }
  .footer-gmail div span,.footer-gmail div button{
    font-size: 18px;
  }
  .flexed{
    margin: 0 !important;
    display: flex;
    align-items: center;
  }
  .samsakhurebi1 .socials {
    margin-top: 20px;
  }
  .samsakhurebi1 .share{
    padding-left: 10px;
  }
 

  .content  iframe{
    height: 370px !important;
  }
  .topFooter div:hover {
    bottom: 0;
  }
  
  .dropdown{
    border-left: 0 !important;
  }
  
  .dropdown-item{
    text-align: center;
  }
  .slick-slide img, div.slick-active,.slick-slide, .slick-slider, .content{
    height: 370px !important;
  }
  .carouselText span{
    font-size: 15px !important;
  }
  .carouselText{
    width: 100%;
    padding-right: 30px !important;
    top: 100px !important;
  }
  .slick-slide button{
    bottom: -50px !important;
    transition: all 1.5s ease-out;
    width: 130px;
    height: 35px;
  }
  .slick-slide button a{
    font-size: 15px;
  }
  .slick-active button{
    bottom: 40px !important;
    transition: all 1.5s ease-out;
  }
  .slick-dots{
    display: none !important;
    bottom: 45px !important;
    padding-left: 200px !important;
  }
  .slick-slide .carouselText{
    top: 50px !important;
    transition: all 1.5s ease-out;
  }
  .slick-active .carouselText{
    top: 150px !important;
    transition: all 1.5s ease-out;

  }
  .landing-items,.landing-items-container{
    margin-top: 20px !important;
  }
  
  .main-content{
    width: 100% !important;
    
    
  }
  .main{
    margin-bottom: 50px;
  }
  .main-content-text{
    width: auto;
  }
  .news1-container .main-content:last-child{
    margin-bottom: 0;
  }
  .footer-nav{
    flex-direction: column;
  }
  .sakrebulo-landing-paragraphs > span img{
    height: auto;
  }
}


@media only screen and (min-width: 992px){
  .moadgileebi--grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}




