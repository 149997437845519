.slider-track {
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

.slick-slider {
  height: 100%;
  position: relative;
  z-index: 1;
  
  img {
    width: 100%;
    transition: all 2s cubic-bezier(0.7, 0, 0.3, 1) !important;
    transform: scale(1.2) !important;
  }
  
  &.slick-active img {
    transform: scale(1);
  }
}